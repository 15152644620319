import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { fluidRange, position, size } from 'polished';
import Img from 'gatsby-image';
import Label from '../../base/label';
import Button from '../../base/button';
import breakpoints from '../../../styles/settings/breakpoints';

const cardDefault = ({ content }) => {
    const { image, title, description, link, label, publicationDate } = content;

    const getLabel = () => {
        if (label) {
            return (
                <SLabel>
                    <Label>{label}</Label>
                </SLabel>
            );
        }
    };

    const getPublicationDate = () => {
        const months = [
            'januari',
            'februari',
            'maart',
            'april',
            'mei',
            'juni',
            'juli',
            'augustus',
            'september',
            'october',
            'novemeber',
            'december'
        ];
        let newPublicationDate = new Date(publicationDate);
        let formattedDate = `${newPublicationDate.getDate()} ${
            months[newPublicationDate.getMonth()]
        } ${newPublicationDate.getFullYear()}`;

        if (publicationDate) {
            return <SDate datetime={publicationDate}>{formattedDate}</SDate>;
        }
    };
    return (
        <SCard>
            <SImage>
                <Img alt={title} fluid={image} />
                {getLabel()}
            </SImage>
            <SContent>
                {getPublicationDate()}
                <SHeading>{title}</SHeading>
                <SDescription>{description}</SDescription>
                <SCardButton>
                    <Button small="true" to={link}>
                        Lees meer
                    </Button>
                </SCardButton>
            </SContent>
        </SCard>
    );
};

const SCard = styled.div`
    margin-top: 20px;
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    overflow: hidden;

    ${breakpoints.medium} {
        margin-top: 40px;
    }
`;

const SImage = styled.div`
    position: relative;

    &::after {
        ${position('absolute', '0', null, null, '0')}
        ${size('100%', '100%')}
        background-color: var(--color-black);
        opacity: 0.2;
        content: '';
    }
`;

const SLabel = styled.div`
    ${position('absolute', '10px', null, null, '10px')}
    z-index: 1;
`;

const SDate = styled.time`
    display: block;
    margin-bottom: 4px;
    color: var(--color-gray-darker);
`;

const SContent = styled.div`
    padding: 30px 24px;
    background-color: var(--color-white);
`;

const SHeading = styled.h2`
    ${fluidRange(
        {
            prop: 'font-size',
            fromSize: '17px',
            toSize: '21px'
        },
        '320px',
        '1440px'
    )}
    margin-bottom: 12px;
    font-weight: 700;
`;

const SDescription = styled.p``;

const SCardButton = styled.div`
    margin-top: 16px;
`;

export default cardDefault;

cardDefault.propTypes = {
    content: PropTypes.object.isRequired
};
