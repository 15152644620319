import React from 'react';
import styled from 'styled-components';

const Button = ({ children, small }) => {
    return <SLabel>{children}</SLabel>;
};

const SLabel = styled.span`
    padding: 4px 20px;
    border-radius: 20px;
    background-color: var(--color-secondary);
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1.1px;
    font-weight: 700;
    color: var(--color-white);
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
`;

export default Button;
